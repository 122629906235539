import { RELAYER_DEFAULT_PROTOCOL as Et, TRANSPORT_TYPES as f, EVENT_CLIENT_SESSION_TRACES as C, EVENT_CLIENT_SESSION_ERRORS as F, EVENT_CLIENT_AUTHENTICATE_TRACES as Q, EVENT_CLIENT_AUTHENTICATE_ERRORS as se, EVENT_CLIENT_PAIRING_ERRORS as Rt, EVENT_CLIENT_PAIRING_TRACES as St, RELAYER_EVENTS as Ue, VERIFY_SERVER as It, EXPIRER_EVENTS as ft, PAIRING_EVENTS as Ge, Store as H, Core as vt } from "@walletconnect/core";
import { pino as qt, getDefaultLoggerOptions as Tt, generateChildLogger as Nt, getLoggerContext as Pt } from "@walletconnect/logger";
import { IEngine as Ot, ISignClient as At } from "@walletconnect/types";
import { getInternalError as u, calcExpiry as A, createDelayedPromise as Y, engineEvent as g, getSdkError as P, getDeepLink as bt, handleDeeplinkRedirect as Ct, isSessionCompatible as Lt, hashKey as Te, parseChainId as Ne, createEncodedRecap as xt, getRecapFromResources as Pe, mergeEncodedRecaps as Dt, TYPE_2 as Vt, BASE64URL as ie, getLinkModeURL as he, validateSignedCacao as je, getNamespacedDidChainId as Fe, getDidAddress as Qe, getMethodsFromRecap as ze, getChainsFromRecap as He, buildNamespacesFromAuth as Ye, formatMessage as Mt, BASE64 as de, hashMessage as X, isExpired as J, MemoryStore as re, isValidParams as L, isUndefined as ne, isValidRelays as kt, isValidObject as Xe, isValidRequiredNamespaces as $t, isValidNamespaces as Oe, isConformingNamespaces as Je, isValidString as k, isValidErrorReason as Kt, isValidRelay as Ut, isValidController as Gt, isValidNamespacesChainId as Be, isValidRequest as jt, isValidNamespacesRequest as Ft, isValidRequestExpiry as Qt, isValidResponse as zt, isValidEvent as Ht, isValidNamespacesEvent as Yt, getSearchParamFromURL as We, isTestRun as Xt, isReactNative as Jt, parseExpirerTarget as Bt, isValidId as Wt, TYPE_1 as Ae, getAppMetadata as Zt } from "@walletconnect/utils";
import es, { EventEmitter as ts } from "events";
import { THIRTY_DAYS as ss, SEVEN_DAYS as Ze, FIVE_MINUTES as b, ONE_DAY as $, ONE_HOUR as et, ONE_SECOND as is, toMiliseconds as tt } from "@walletconnect/time";
import { payloadId as B, getBigIntRpcId as ue, isJsonRpcResult as K, isJsonRpcError as U, formatJsonRpcRequest as ge, formatJsonRpcResult as rs, formatJsonRpcError as ns, isJsonRpcRequest as os, isJsonRpcResponse as as } from "@walletconnect/jsonrpc-utils";
const be = "wc",
  Ce = 2,
  Le = "client",
  ye = `${be}@${Ce}:${Le}:`,
  we = {
    name: Le,
    logger: "error",
    controller: !1,
    relayUrl: "wss://relay.walletconnect.org"
  },
  cs = {
    session_proposal: "session_proposal",
    session_update: "session_update",
    session_extend: "session_extend",
    session_ping: "session_ping",
    session_delete: "session_delete",
    session_expire: "session_expire",
    session_request: "session_request",
    session_request_sent: "session_request_sent",
    session_event: "session_event",
    proposal_expire: "proposal_expire",
    session_authenticate: "session_authenticate",
    session_request_expire: "session_request_expire"
  },
  ls = {
    database: ":memory:"
  },
  xe = "WALLETCONNECT_DEEPLINK_CHOICE",
  ps = {
    created: "history_created",
    updated: "history_updated",
    deleted: "history_deleted",
    sync: "history_sync"
  },
  hs = "history",
  ds = "0.3",
  st = "proposal",
  us = ss,
  it = "Proposal expired",
  rt = "session",
  z = Ze,
  nt = "engine",
  v = {
    wc_sessionPropose: {
      req: {
        ttl: b,
        prompt: !0,
        tag: 1100
      },
      res: {
        ttl: b,
        prompt: !1,
        tag: 1101
      },
      reject: {
        ttl: b,
        prompt: !1,
        tag: 1120
      },
      autoReject: {
        ttl: b,
        prompt: !1,
        tag: 1121
      }
    },
    wc_sessionSettle: {
      req: {
        ttl: b,
        prompt: !1,
        tag: 1102
      },
      res: {
        ttl: b,
        prompt: !1,
        tag: 1103
      }
    },
    wc_sessionUpdate: {
      req: {
        ttl: $,
        prompt: !1,
        tag: 1104
      },
      res: {
        ttl: $,
        prompt: !1,
        tag: 1105
      }
    },
    wc_sessionExtend: {
      req: {
        ttl: $,
        prompt: !1,
        tag: 1106
      },
      res: {
        ttl: $,
        prompt: !1,
        tag: 1107
      }
    },
    wc_sessionRequest: {
      req: {
        ttl: b,
        prompt: !0,
        tag: 1108
      },
      res: {
        ttl: b,
        prompt: !1,
        tag: 1109
      }
    },
    wc_sessionEvent: {
      req: {
        ttl: b,
        prompt: !0,
        tag: 1110
      },
      res: {
        ttl: b,
        prompt: !1,
        tag: 1111
      }
    },
    wc_sessionDelete: {
      req: {
        ttl: $,
        prompt: !1,
        tag: 1112
      },
      res: {
        ttl: $,
        prompt: !1,
        tag: 1113
      }
    },
    wc_sessionPing: {
      req: {
        ttl: $,
        prompt: !1,
        tag: 1114
      },
      res: {
        ttl: $,
        prompt: !1,
        tag: 1115
      }
    },
    wc_sessionAuthenticate: {
      req: {
        ttl: et,
        prompt: !0,
        tag: 1116
      },
      res: {
        ttl: et,
        prompt: !1,
        tag: 1117
      },
      reject: {
        ttl: b,
        prompt: !1,
        tag: 1118
      },
      autoReject: {
        ttl: b,
        prompt: !1,
        tag: 1119
      }
    }
  },
  me = {
    min: b,
    max: Ze
  },
  x = {
    idle: "IDLE",
    active: "ACTIVE"
  },
  ot = "request",
  at = ["wc_sessionPropose", "wc_sessionRequest", "wc_authRequest", "wc_sessionAuthenticate"],
  ct = "wc",
  gs = 1.5,
  lt = "auth",
  pt = "authKeys",
  ht = "pairingTopics",
  dt = "requests",
  oe = `${ct}@${1.5}:${lt}:`,
  ae = `${oe}:PUB_KEY`;
var ys = Object.defineProperty,
  ws = Object.defineProperties,
  ms = Object.getOwnPropertyDescriptors,
  ut = Object.getOwnPropertySymbols,
  _s = Object.prototype.hasOwnProperty,
  Es = Object.prototype.propertyIsEnumerable,
  gt = (q, o, e) => o in q ? ys(q, o, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: e
  }) : q[o] = e,
  I = (q, o) => {
    for (var e in o || (o = {})) _s.call(o, e) && gt(q, e, o[e]);
    if (ut) for (var e of ut(o)) Es.call(o, e) && gt(q, e, o[e]);
    return q;
  },
  D = (q, o) => ws(q, ms(o));
class Rs extends Ot {
  constructor(o) {
    super(o), this.name = nt, this.events = new es(), this.initialized = !1, this.requestQueue = {
      state: x.idle,
      queue: []
    }, this.sessionRequestQueue = {
      state: x.idle,
      queue: []
    }, this.requestQueueDelay = is, this.expectedPairingMethodMap = new Map(), this.recentlyDeletedMap = new Map(), this.recentlyDeletedLimit = 200, this.relayMessageCache = [], this.init = async () => {
      this.initialized || (await this.cleanup(), this.registerRelayerEvents(), this.registerExpirerEvents(), this.registerPairingEvents(), await this.registerLinkModeListeners(), this.client.core.pairing.register({
        methods: Object.keys(v)
      }), this.initialized = !0, setTimeout(() => {
        this.sessionRequestQueue.queue = this.getPendingSessionRequests(), this.processSessionRequestQueue();
      }, tt(this.requestQueueDelay)));
    }, this.connect = async e => {
      this.isInitialized(), await this.confirmOnlineStateOrThrow();
      const t = D(I({}, e), {
        requiredNamespaces: e.requiredNamespaces || {},
        optionalNamespaces: e.optionalNamespaces || {}
      });
      await this.isValidConnect(t);
      const {
        pairingTopic: s,
        requiredNamespaces: i,
        optionalNamespaces: r,
        sessionProperties: n,
        relays: a
      } = t;
      let c = s,
        h,
        p = !1;
      try {
        c && (p = this.client.core.pairing.pairings.get(c).active);
      } catch (E) {
        throw this.client.logger.error(`connect() -> pairing.get(${c}) failed`), E;
      }
      if (!c || !p) {
        const {
          topic: E,
          uri: S
        } = await this.client.core.pairing.create();
        c = E, h = S;
      }
      if (!c) {
        const {
          message: E
        } = u("NO_MATCHING_KEY", `connect() pairing topic: ${c}`);
        throw new Error(E);
      }
      const d = await this.client.core.crypto.generateKeyPair(),
        l = v.wc_sessionPropose.req.ttl || b,
        w = A(l),
        m = I({
          requiredNamespaces: i,
          optionalNamespaces: r,
          relays: a ?? [{
            protocol: Et
          }],
          proposer: {
            publicKey: d,
            metadata: this.client.metadata
          },
          expiryTimestamp: w,
          pairingTopic: c
        }, n && {
          sessionProperties: n
        }),
        {
          reject: y,
          resolve: _,
          done: R
        } = Y(l, it);
      this.events.once(g("session_connect"), async ({
        error: E,
        session: S
      }) => {
        if (E) y(E);else if (S) {
          S.self.publicKey = d;
          const M = D(I({}, S), {
            pairingTopic: m.pairingTopic,
            requiredNamespaces: m.requiredNamespaces,
            optionalNamespaces: m.optionalNamespaces,
            transportType: f.relay
          });
          await this.client.session.set(S.topic, M), await this.setExpiry(S.topic, S.expiry), c && (await this.client.core.pairing.updateMetadata({
            topic: c,
            metadata: S.peer.metadata
          })), this.cleanupDuplicatePairings(M), _(M);
        }
      });
      const V = await this.sendRequest({
        topic: c,
        method: "wc_sessionPropose",
        params: m,
        throwOnFailedPublish: !0
      });
      return await this.setProposal(V, I({
        id: V
      }, m)), {
        uri: h,
        approval: R
      };
    }, this.pair = async e => {
      this.isInitialized(), await this.confirmOnlineStateOrThrow();
      try {
        return await this.client.core.pairing.pair(e);
      } catch (t) {
        throw this.client.logger.error("pair() failed"), t;
      }
    }, this.approve = async e => {
      var t, s, i;
      const r = this.client.core.eventClient.createEvent({
        properties: {
          topic: (t = e?.id) == null ? void 0 : t.toString(),
          trace: [C.session_approve_started]
        }
      });
      try {
        this.isInitialized(), await this.confirmOnlineStateOrThrow();
      } catch (N) {
        throw r.setError(F.no_internet_connection), N;
      }
      try {
        await this.isValidProposalId(e?.id);
      } catch (N) {
        throw this.client.logger.error(`approve() -> proposal.get(${e?.id}) failed`), r.setError(F.proposal_not_found), N;
      }
      try {
        await this.isValidApprove(e);
      } catch (N) {
        throw this.client.logger.error("approve() -> isValidApprove() failed"), r.setError(F.session_approve_namespace_validation_failure), N;
      }
      const {
          id: n,
          relayProtocol: a,
          namespaces: c,
          sessionProperties: h,
          sessionConfig: p
        } = e,
        d = this.client.proposal.get(n);
      this.client.core.eventClient.deleteEvent({
        eventId: r.eventId
      });
      const {
        pairingTopic: l,
        proposer: w,
        requiredNamespaces: m,
        optionalNamespaces: y
      } = d;
      let _ = (s = this.client.core.eventClient) == null ? void 0 : s.getEvent({
        topic: l
      });
      _ || (_ = (i = this.client.core.eventClient) == null ? void 0 : i.createEvent({
        type: C.session_approve_started,
        properties: {
          topic: l,
          trace: [C.session_approve_started, C.session_namespaces_validation_success]
        }
      }));
      const R = await this.client.core.crypto.generateKeyPair(),
        V = w.publicKey,
        E = await this.client.core.crypto.generateSharedKey(R, V),
        S = I(I({
          relay: {
            protocol: a ?? "irn"
          },
          namespaces: c,
          controller: {
            publicKey: R,
            metadata: this.client.metadata
          },
          expiry: A(z)
        }, h && {
          sessionProperties: h
        }), p && {
          sessionConfig: p
        }),
        M = f.relay;
      _.addTrace(C.subscribing_session_topic);
      try {
        await this.client.core.relayer.subscribe(E, {
          transportType: M
        });
      } catch (N) {
        throw _.setError(F.subscribe_session_topic_failure), N;
      }
      _.addTrace(C.subscribe_session_topic_success);
      const W = D(I({}, S), {
        topic: E,
        requiredNamespaces: m,
        optionalNamespaces: y,
        pairingTopic: l,
        acknowledged: !1,
        self: S.controller,
        peer: {
          publicKey: w.publicKey,
          metadata: w.metadata
        },
        controller: R,
        transportType: f.relay
      });
      await this.client.session.set(E, W), _.addTrace(C.store_session);
      try {
        _.addTrace(C.publishing_session_settle), await this.sendRequest({
          topic: E,
          method: "wc_sessionSettle",
          params: S,
          throwOnFailedPublish: !0
        }).catch(N => {
          throw _?.setError(F.session_settle_publish_failure), N;
        }), _.addTrace(C.session_settle_publish_success), _.addTrace(C.publishing_session_approve), await this.sendResult({
          id: n,
          topic: l,
          result: {
            relay: {
              protocol: a ?? "irn"
            },
            responderPublicKey: R
          },
          throwOnFailedPublish: !0
        }).catch(N => {
          throw _?.setError(F.session_approve_publish_failure), N;
        }), _.addTrace(C.session_approve_publish_success);
      } catch (N) {
        throw this.client.logger.error(N), this.client.session.delete(E, P("USER_DISCONNECTED")), await this.client.core.relayer.unsubscribe(E), N;
      }
      return this.client.core.eventClient.deleteEvent({
        eventId: _.eventId
      }), await this.client.core.pairing.updateMetadata({
        topic: l,
        metadata: w.metadata
      }), await this.client.proposal.delete(n, P("USER_DISCONNECTED")), await this.client.core.pairing.activate({
        topic: l
      }), await this.setExpiry(E, A(z)), {
        topic: E,
        acknowledged: () => Promise.resolve(this.client.session.get(E))
      };
    }, this.reject = async e => {
      this.isInitialized(), await this.confirmOnlineStateOrThrow();
      try {
        await this.isValidReject(e);
      } catch (r) {
        throw this.client.logger.error("reject() -> isValidReject() failed"), r;
      }
      const {
        id: t,
        reason: s
      } = e;
      let i;
      try {
        i = this.client.proposal.get(t).pairingTopic;
      } catch (r) {
        throw this.client.logger.error(`reject() -> proposal.get(${t}) failed`), r;
      }
      i && (await this.sendError({
        id: t,
        topic: i,
        error: s,
        rpcOpts: v.wc_sessionPropose.reject
      }), await this.client.proposal.delete(t, P("USER_DISCONNECTED")));
    }, this.update = async e => {
      this.isInitialized(), await this.confirmOnlineStateOrThrow();
      try {
        await this.isValidUpdate(e);
      } catch (p) {
        throw this.client.logger.error("update() -> isValidUpdate() failed"), p;
      }
      const {
          topic: t,
          namespaces: s
        } = e,
        {
          done: i,
          resolve: r,
          reject: n
        } = Y(),
        a = B(),
        c = ue().toString(),
        h = this.client.session.get(t).namespaces;
      return this.events.once(g("session_update", a), ({
        error: p
      }) => {
        p ? n(p) : r();
      }), await this.client.session.update(t, {
        namespaces: s
      }), await this.sendRequest({
        topic: t,
        method: "wc_sessionUpdate",
        params: {
          namespaces: s
        },
        throwOnFailedPublish: !0,
        clientRpcId: a,
        relayRpcId: c
      }).catch(p => {
        this.client.logger.error(p), this.client.session.update(t, {
          namespaces: h
        }), n(p);
      }), {
        acknowledged: i
      };
    }, this.extend = async e => {
      this.isInitialized(), await this.confirmOnlineStateOrThrow();
      try {
        await this.isValidExtend(e);
      } catch (a) {
        throw this.client.logger.error("extend() -> isValidExtend() failed"), a;
      }
      const {
          topic: t
        } = e,
        s = B(),
        {
          done: i,
          resolve: r,
          reject: n
        } = Y();
      return this.events.once(g("session_extend", s), ({
        error: a
      }) => {
        a ? n(a) : r();
      }), await this.setExpiry(t, A(z)), this.sendRequest({
        topic: t,
        method: "wc_sessionExtend",
        params: {},
        clientRpcId: s,
        throwOnFailedPublish: !0
      }).catch(a => {
        n(a);
      }), {
        acknowledged: i
      };
    }, this.request = async e => {
      this.isInitialized();
      try {
        await this.isValidRequest(e);
      } catch (w) {
        throw this.client.logger.error("request() -> isValidRequest() failed"), w;
      }
      const {
          chainId: t,
          request: s,
          topic: i,
          expiry: r = v.wc_sessionRequest.req.ttl
        } = e,
        n = this.client.session.get(i);
      n?.transportType === f.relay && (await this.confirmOnlineStateOrThrow());
      const a = B(),
        c = ue().toString(),
        {
          done: h,
          resolve: p,
          reject: d
        } = Y(r, "Request expired. Please try again.");
      this.events.once(g("session_request", a), ({
        error: w,
        result: m
      }) => {
        w ? d(w) : p(m);
      });
      const l = this.getAppLinkIfEnabled(n.peer.metadata, n.transportType);
      return l ? (await this.sendRequest({
        clientRpcId: a,
        relayRpcId: c,
        topic: i,
        method: "wc_sessionRequest",
        params: {
          request: D(I({}, s), {
            expiryTimestamp: A(r)
          }),
          chainId: t
        },
        expiry: r,
        throwOnFailedPublish: !0,
        appLink: l
      }).catch(w => d(w)), this.client.events.emit("session_request_sent", {
        topic: i,
        request: s,
        chainId: t,
        id: a
      }), await h()) : await Promise.all([new Promise(async w => {
        await this.sendRequest({
          clientRpcId: a,
          relayRpcId: c,
          topic: i,
          method: "wc_sessionRequest",
          params: {
            request: D(I({}, s), {
              expiryTimestamp: A(r)
            }),
            chainId: t
          },
          expiry: r,
          throwOnFailedPublish: !0
        }).catch(m => d(m)), this.client.events.emit("session_request_sent", {
          topic: i,
          request: s,
          chainId: t,
          id: a
        }), w();
      }), new Promise(async w => {
        var m;
        if (!((m = n.sessionConfig) != null && m.disableDeepLink)) {
          const y = await bt(this.client.core.storage, xe);
          await Ct({
            id: a,
            topic: i,
            wcDeepLink: y
          });
        }
        w();
      }), h()]).then(w => w[2]);
    }, this.respond = async e => {
      this.isInitialized(), await this.isValidRespond(e);
      const {
          topic: t,
          response: s
        } = e,
        {
          id: i
        } = s,
        r = this.client.session.get(t);
      r.transportType === f.relay && (await this.confirmOnlineStateOrThrow());
      const n = this.getAppLinkIfEnabled(r.peer.metadata, r.transportType);
      K(s) ? await this.sendResult({
        id: i,
        topic: t,
        result: s.result,
        throwOnFailedPublish: !0,
        appLink: n
      }) : U(s) && (await this.sendError({
        id: i,
        topic: t,
        error: s.error,
        appLink: n
      })), this.cleanupAfterResponse(e);
    }, this.ping = async e => {
      this.isInitialized(), await this.confirmOnlineStateOrThrow();
      try {
        await this.isValidPing(e);
      } catch (s) {
        throw this.client.logger.error("ping() -> isValidPing() failed"), s;
      }
      const {
        topic: t
      } = e;
      if (this.client.session.keys.includes(t)) {
        const s = B(),
          i = ue().toString(),
          {
            done: r,
            resolve: n,
            reject: a
          } = Y();
        this.events.once(g("session_ping", s), ({
          error: c
        }) => {
          c ? a(c) : n();
        }), await Promise.all([this.sendRequest({
          topic: t,
          method: "wc_sessionPing",
          params: {},
          throwOnFailedPublish: !0,
          clientRpcId: s,
          relayRpcId: i
        }), r()]);
      } else this.client.core.pairing.pairings.keys.includes(t) && (await this.client.core.pairing.ping({
        topic: t
      }));
    }, this.emit = async e => {
      this.isInitialized(), await this.confirmOnlineStateOrThrow(), await this.isValidEmit(e);
      const {
          topic: t,
          event: s,
          chainId: i
        } = e,
        r = ue().toString();
      await this.sendRequest({
        topic: t,
        method: "wc_sessionEvent",
        params: {
          event: s,
          chainId: i
        },
        throwOnFailedPublish: !0,
        relayRpcId: r
      });
    }, this.disconnect = async e => {
      this.isInitialized(), await this.confirmOnlineStateOrThrow(), await this.isValidDisconnect(e);
      const {
        topic: t
      } = e;
      if (this.client.session.keys.includes(t)) await this.sendRequest({
        topic: t,
        method: "wc_sessionDelete",
        params: P("USER_DISCONNECTED"),
        throwOnFailedPublish: !0
      }), await this.deleteSession({
        topic: t,
        emitEvent: !1
      });else if (this.client.core.pairing.pairings.keys.includes(t)) await this.client.core.pairing.disconnect({
        topic: t
      });else {
        const {
          message: s
        } = u("MISMATCHED_TOPIC", `Session or pairing topic not found: ${t}`);
        throw new Error(s);
      }
    }, this.find = e => (this.isInitialized(), this.client.session.getAll().filter(t => Lt(t, e))), this.getPendingSessionRequests = () => this.client.pendingRequest.getAll(), this.authenticate = async (e, t) => {
      var s;
      this.isInitialized(), this.isValidAuthenticate(e);
      const i = t && this.client.core.linkModeSupportedApps.includes(t) && ((s = this.client.metadata.redirect) == null ? void 0 : s.linkMode),
        r = i ? f.link_mode : f.relay;
      r === f.relay && (await this.confirmOnlineStateOrThrow());
      const {
          chains: n,
          statement: a = "",
          uri: c,
          domain: h,
          nonce: p,
          type: d,
          exp: l,
          nbf: w,
          methods: m = [],
          expiry: y
        } = e,
        _ = [...(e.resources || [])],
        {
          topic: R,
          uri: V
        } = await this.client.core.pairing.create({
          methods: ["wc_sessionAuthenticate"],
          transportType: r
        });
      this.client.logger.info({
        message: "Generated new pairing",
        pairing: {
          topic: R,
          uri: V
        }
      });
      const E = await this.client.core.crypto.generateKeyPair(),
        S = Te(E);
      if (await Promise.all([this.client.auth.authKeys.set(ae, {
        responseTopic: S,
        publicKey: E
      }), this.client.auth.pairingTopics.set(S, {
        topic: S,
        pairingTopic: R
      })]), await this.client.core.relayer.subscribe(S, {
        transportType: r
      }), this.client.logger.info(`sending request to new pairing topic: ${R}`), m.length > 0) {
        const {
          namespace: O
        } = Ne(n[0]);
        let T = xt(O, "request", m);
        Pe(_) && (T = Dt(T, _.pop())), _.push(T);
      }
      const M = y && y > v.wc_sessionAuthenticate.req.ttl ? y : v.wc_sessionAuthenticate.req.ttl,
        W = {
          authPayload: {
            type: d ?? "caip122",
            chains: n,
            statement: a,
            aud: c,
            domain: h,
            version: "1",
            nonce: p,
            iat: new Date().toISOString(),
            exp: l,
            nbf: w,
            resources: _
          },
          requester: {
            publicKey: E,
            metadata: this.client.metadata
          },
          expiryTimestamp: A(M)
        },
        N = {
          eip155: {
            chains: n,
            methods: [...new Set(["personal_sign", ...m])],
            events: ["chainChanged", "accountsChanged"]
          }
        },
        De = {
          requiredNamespaces: {},
          optionalNamespaces: N,
          relays: [{
            protocol: "irn"
          }],
          pairingTopic: R,
          proposer: {
            publicKey: E,
            metadata: this.client.metadata
          },
          expiryTimestamp: A(v.wc_sessionPropose.req.ttl)
        },
        {
          done: wt,
          resolve: Ve,
          reject: Ee
        } = Y(M, "Request expired"),
        ce = async ({
          error: O,
          session: T
        }) => {
          if (this.events.off(g("session_request", G), Re), O) Ee(O);else if (T) {
            T.self.publicKey = E, await this.client.session.set(T.topic, T), await this.setExpiry(T.topic, T.expiry), R && (await this.client.core.pairing.updateMetadata({
              topic: R,
              metadata: T.peer.metadata
            }));
            const le = this.client.session.get(T.topic);
            await this.deleteProposal(Z), Ve({
              session: le
            });
          }
        },
        Re = async O => {
          var T, le, Me;
          if (await this.deletePendingAuthRequest(G, {
            message: "fulfilled",
            code: 0
          }), O.error) {
            const te = P("WC_METHOD_UNSUPPORTED", "wc_sessionAuthenticate");
            return O.error.code === te.code ? void 0 : (this.events.off(g("session_connect"), ce), Ee(O.error.message));
          }
          await this.deleteProposal(Z), this.events.off(g("session_connect"), ce);
          const {
              cacaos: ke,
              responder: j
            } = O.result,
            Ie = [],
            $e = [];
          for (const te of ke) {
            (await je({
              cacao: te,
              projectId: this.client.core.projectId
            })) || (this.client.logger.error(te, "Signature verification failed"), Ee(P("SESSION_SETTLEMENT_FAILED", "Signature verification failed")));
            const {
                p: fe
              } = te,
              ve = Pe(fe.resources),
              Ke = [Fe(fe.iss)],
              mt = Qe(fe.iss);
            if (ve) {
              const qe = ze(ve),
                _t = He(ve);
              Ie.push(...qe), Ke.push(..._t);
            }
            for (const qe of Ke) $e.push(`${qe}:${mt}`);
          }
          const ee = await this.client.core.crypto.generateSharedKey(E, j.publicKey);
          let pe;
          Ie.length > 0 && (pe = {
            topic: ee,
            acknowledged: !0,
            self: {
              publicKey: E,
              metadata: this.client.metadata
            },
            peer: j,
            controller: j.publicKey,
            expiry: A(z),
            requiredNamespaces: {},
            optionalNamespaces: {},
            relay: {
              protocol: "irn"
            },
            pairingTopic: R,
            namespaces: Ye([...new Set(Ie)], [...new Set($e)]),
            transportType: r
          }, await this.client.core.relayer.subscribe(ee, {
            transportType: r
          }), await this.client.session.set(ee, pe), R && (await this.client.core.pairing.updateMetadata({
            topic: R,
            metadata: j.metadata
          })), pe = this.client.session.get(ee)), (T = this.client.metadata.redirect) != null && T.linkMode && (le = j.metadata.redirect) != null && le.linkMode && (Me = j.metadata.redirect) != null && Me.universal && t && (this.client.core.addLinkModeSupportedApp(j.metadata.redirect.universal), this.client.session.update(ee, {
            transportType: f.link_mode
          })), Ve({
            auths: ke,
            session: pe
          });
        },
        G = B(),
        Z = B();
      this.events.once(g("session_connect"), ce), this.events.once(g("session_request", G), Re);
      let Se;
      try {
        if (i) {
          const O = ge("wc_sessionAuthenticate", W, G);
          this.client.core.history.set(R, O);
          const T = await this.client.core.crypto.encode("", O, {
            type: Vt,
            encoding: ie
          });
          Se = he(t, R, T);
        } else await Promise.all([this.sendRequest({
          topic: R,
          method: "wc_sessionAuthenticate",
          params: W,
          expiry: e.expiry,
          throwOnFailedPublish: !0,
          clientRpcId: G
        }), this.sendRequest({
          topic: R,
          method: "wc_sessionPropose",
          params: De,
          expiry: v.wc_sessionPropose.req.ttl,
          throwOnFailedPublish: !0,
          clientRpcId: Z
        })]);
      } catch (O) {
        throw this.events.off(g("session_connect"), ce), this.events.off(g("session_request", G), Re), O;
      }
      return await this.setProposal(Z, I({
        id: Z
      }, De)), await this.setAuthRequest(G, {
        request: D(I({}, W), {
          verifyContext: {}
        }),
        pairingTopic: R,
        transportType: r
      }), {
        uri: Se ?? V,
        response: wt
      };
    }, this.approveSessionAuthenticate = async e => {
      const {
          id: t,
          auths: s
        } = e,
        i = this.client.core.eventClient.createEvent({
          properties: {
            topic: t.toString(),
            trace: [Q.authenticated_session_approve_started]
          }
        });
      try {
        this.isInitialized();
      } catch (y) {
        throw i.setError(se.no_internet_connection), y;
      }
      const r = this.getPendingAuthRequest(t);
      if (!r) throw i.setError(se.authenticated_session_pending_request_not_found), new Error(`Could not find pending auth request with id ${t}`);
      const n = r.transportType || f.relay;
      n === f.relay && (await this.confirmOnlineStateOrThrow());
      const a = r.requester.publicKey,
        c = await this.client.core.crypto.generateKeyPair(),
        h = Te(a),
        p = {
          type: Ae,
          receiverPublicKey: a,
          senderPublicKey: c
        },
        d = [],
        l = [];
      for (const y of s) {
        if (!(await je({
          cacao: y,
          projectId: this.client.core.projectId
        }))) {
          i.setError(se.invalid_cacao);
          const S = P("SESSION_SETTLEMENT_FAILED", "Signature verification failed");
          throw await this.sendError({
            id: t,
            topic: h,
            error: S,
            encodeOpts: p
          }), new Error(S.message);
        }
        i.addTrace(Q.cacaos_verified);
        const {
            p: _
          } = y,
          R = Pe(_.resources),
          V = [Fe(_.iss)],
          E = Qe(_.iss);
        if (R) {
          const S = ze(R),
            M = He(R);
          d.push(...S), V.push(...M);
        }
        for (const S of V) l.push(`${S}:${E}`);
      }
      const w = await this.client.core.crypto.generateSharedKey(c, a);
      i.addTrace(Q.create_authenticated_session_topic);
      let m;
      if (d?.length > 0) {
        m = {
          topic: w,
          acknowledged: !0,
          self: {
            publicKey: c,
            metadata: this.client.metadata
          },
          peer: {
            publicKey: a,
            metadata: r.requester.metadata
          },
          controller: a,
          expiry: A(z),
          authentication: s,
          requiredNamespaces: {},
          optionalNamespaces: {},
          relay: {
            protocol: "irn"
          },
          pairingTopic: r.pairingTopic,
          namespaces: Ye([...new Set(d)], [...new Set(l)]),
          transportType: n
        }, i.addTrace(Q.subscribing_authenticated_session_topic);
        try {
          await this.client.core.relayer.subscribe(w, {
            transportType: n
          });
        } catch (y) {
          throw i.setError(se.subscribe_authenticated_session_topic_failure), y;
        }
        i.addTrace(Q.subscribe_authenticated_session_topic_success), await this.client.session.set(w, m), i.addTrace(Q.store_authenticated_session), await this.client.core.pairing.updateMetadata({
          topic: r.pairingTopic,
          metadata: r.requester.metadata
        });
      }
      i.addTrace(Q.publishing_authenticated_session_approve);
      try {
        await this.sendResult({
          topic: h,
          id: t,
          result: {
            cacaos: s,
            responder: {
              publicKey: c,
              metadata: this.client.metadata
            }
          },
          encodeOpts: p,
          throwOnFailedPublish: !0,
          appLink: this.getAppLinkIfEnabled(r.requester.metadata, n)
        });
      } catch (y) {
        throw i.setError(se.authenticated_session_approve_publish_failure), y;
      }
      return await this.client.auth.requests.delete(t, {
        message: "fulfilled",
        code: 0
      }), await this.client.core.pairing.activate({
        topic: r.pairingTopic
      }), this.client.core.eventClient.deleteEvent({
        eventId: i.eventId
      }), {
        session: m
      };
    }, this.rejectSessionAuthenticate = async e => {
      this.isInitialized();
      const {
          id: t,
          reason: s
        } = e,
        i = this.getPendingAuthRequest(t);
      if (!i) throw new Error(`Could not find pending auth request with id ${t}`);
      i.transportType === f.relay && (await this.confirmOnlineStateOrThrow());
      const r = i.requester.publicKey,
        n = await this.client.core.crypto.generateKeyPair(),
        a = Te(r),
        c = {
          type: Ae,
          receiverPublicKey: r,
          senderPublicKey: n
        };
      await this.sendError({
        id: t,
        topic: a,
        error: s,
        encodeOpts: c,
        rpcOpts: v.wc_sessionAuthenticate.reject,
        appLink: this.getAppLinkIfEnabled(i.requester.metadata, i.transportType)
      }), await this.client.auth.requests.delete(t, {
        message: "rejected",
        code: 0
      }), await this.client.proposal.delete(t, P("USER_DISCONNECTED"));
    }, this.formatAuthMessage = e => {
      this.isInitialized();
      const {
        request: t,
        iss: s
      } = e;
      return Mt(t, s);
    }, this.processRelayMessageCache = () => {
      setTimeout(async () => {
        if (this.relayMessageCache.length !== 0) for (; this.relayMessageCache.length > 0;) try {
          const e = this.relayMessageCache.shift();
          e && (await this.onRelayMessage(e));
        } catch (e) {
          this.client.logger.error(e);
        }
      }, 50);
    }, this.cleanupDuplicatePairings = async e => {
      if (e.pairingTopic) try {
        const t = this.client.core.pairing.pairings.get(e.pairingTopic),
          s = this.client.core.pairing.pairings.getAll().filter(i => {
            var r, n;
            return ((r = i.peerMetadata) == null ? void 0 : r.url) && ((n = i.peerMetadata) == null ? void 0 : n.url) === e.peer.metadata.url && i.topic && i.topic !== t.topic;
          });
        if (s.length === 0) return;
        this.client.logger.info(`Cleaning up ${s.length} duplicate pairing(s)`), await Promise.all(s.map(i => this.client.core.pairing.disconnect({
          topic: i.topic
        }))), this.client.logger.info("Duplicate pairings clean up finished");
      } catch (t) {
        this.client.logger.error(t);
      }
    }, this.deleteSession = async e => {
      var t;
      const {
          topic: s,
          expirerHasDeleted: i = !1,
          emitEvent: r = !0,
          id: n = 0
        } = e,
        {
          self: a
        } = this.client.session.get(s);
      await this.client.core.relayer.unsubscribe(s), await this.client.session.delete(s, P("USER_DISCONNECTED")), this.addToRecentlyDeleted(s, "session"), this.client.core.crypto.keychain.has(a.publicKey) && (await this.client.core.crypto.deleteKeyPair(a.publicKey)), this.client.core.crypto.keychain.has(s) && (await this.client.core.crypto.deleteSymKey(s)), i || this.client.core.expirer.del(s), this.client.core.storage.removeItem(xe).catch(c => this.client.logger.warn(c)), this.getPendingSessionRequests().forEach(c => {
        c.topic === s && this.deletePendingSessionRequest(c.id, P("USER_DISCONNECTED"));
      }), s === ((t = this.sessionRequestQueue.queue[0]) == null ? void 0 : t.topic) && (this.sessionRequestQueue.state = x.idle), r && this.client.events.emit("session_delete", {
        id: n,
        topic: s
      });
    }, this.deleteProposal = async (e, t) => {
      if (t) try {
        const s = this.client.proposal.get(e),
          i = this.client.core.eventClient.getEvent({
            topic: s.pairingTopic
          });
        i?.setError(F.proposal_expired);
      } catch {}
      await Promise.all([this.client.proposal.delete(e, P("USER_DISCONNECTED")), t ? Promise.resolve() : this.client.core.expirer.del(e)]), this.addToRecentlyDeleted(e, "proposal");
    }, this.deletePendingSessionRequest = async (e, t, s = !1) => {
      await Promise.all([this.client.pendingRequest.delete(e, t), s ? Promise.resolve() : this.client.core.expirer.del(e)]), this.addToRecentlyDeleted(e, "request"), this.sessionRequestQueue.queue = this.sessionRequestQueue.queue.filter(i => i.id !== e), s && (this.sessionRequestQueue.state = x.idle, this.client.events.emit("session_request_expire", {
        id: e
      }));
    }, this.deletePendingAuthRequest = async (e, t, s = !1) => {
      await Promise.all([this.client.auth.requests.delete(e, t), s ? Promise.resolve() : this.client.core.expirer.del(e)]);
    }, this.setExpiry = async (e, t) => {
      this.client.session.keys.includes(e) && (this.client.core.expirer.set(e, t), await this.client.session.update(e, {
        expiry: t
      }));
    }, this.setProposal = async (e, t) => {
      this.client.core.expirer.set(e, A(v.wc_sessionPropose.req.ttl)), await this.client.proposal.set(e, t);
    }, this.setAuthRequest = async (e, t) => {
      const {
        request: s,
        pairingTopic: i,
        transportType: r = f.relay
      } = t;
      this.client.core.expirer.set(e, s.expiryTimestamp), await this.client.auth.requests.set(e, {
        authPayload: s.authPayload,
        requester: s.requester,
        expiryTimestamp: s.expiryTimestamp,
        id: e,
        pairingTopic: i,
        verifyContext: s.verifyContext,
        transportType: r
      });
    }, this.setPendingSessionRequest = async e => {
      const {
          id: t,
          topic: s,
          params: i,
          verifyContext: r
        } = e,
        n = i.request.expiryTimestamp || A(v.wc_sessionRequest.req.ttl);
      this.client.core.expirer.set(t, n), await this.client.pendingRequest.set(t, {
        id: t,
        topic: s,
        params: i,
        verifyContext: r
      });
    }, this.sendRequest = async e => {
      const {
          topic: t,
          method: s,
          params: i,
          expiry: r,
          relayRpcId: n,
          clientRpcId: a,
          throwOnFailedPublish: c,
          appLink: h
        } = e,
        p = ge(s, i, a);
      let d;
      const l = !!h;
      try {
        const y = l ? ie : de;
        d = await this.client.core.crypto.encode(t, p, {
          encoding: y
        });
      } catch (y) {
        throw await this.cleanup(), this.client.logger.error(`sendRequest() -> core.crypto.encode() for topic ${t} failed`), y;
      }
      let w;
      if (at.includes(s)) {
        const y = X(JSON.stringify(p)),
          _ = X(d);
        w = await this.client.core.verify.register({
          id: _,
          decryptedId: y
        });
      }
      const m = v[s].req;
      if (m.attestation = w, r && (m.ttl = r), n && (m.id = n), this.client.core.history.set(t, p), l) {
        const y = he(h, t, d);
        await global.Linking.openURL(y, this.client.name);
      } else {
        const y = v[s].req;
        r && (y.ttl = r), n && (y.id = n), c ? (y.internal = D(I({}, y.internal), {
          throwOnFailedPublish: !0
        }), await this.client.core.relayer.publish(t, d, y)) : this.client.core.relayer.publish(t, d, y).catch(_ => this.client.logger.error(_));
      }
      return p.id;
    }, this.sendResult = async e => {
      const {
          id: t,
          topic: s,
          result: i,
          throwOnFailedPublish: r,
          encodeOpts: n,
          appLink: a
        } = e,
        c = rs(t, i);
      let h;
      const p = a && typeof (global == null ? void 0 : global.Linking) < "u";
      try {
        const l = p ? ie : de;
        h = await this.client.core.crypto.encode(s, c, D(I({}, n || {}), {
          encoding: l
        }));
      } catch (l) {
        throw await this.cleanup(), this.client.logger.error(`sendResult() -> core.crypto.encode() for topic ${s} failed`), l;
      }
      let d;
      try {
        d = await this.client.core.history.get(s, t);
      } catch (l) {
        throw this.client.logger.error(`sendResult() -> history.get(${s}, ${t}) failed`), l;
      }
      if (p) {
        const l = he(a, s, h);
        await global.Linking.openURL(l, this.client.name);
      } else {
        const l = v[d.request.method].res;
        r ? (l.internal = D(I({}, l.internal), {
          throwOnFailedPublish: !0
        }), await this.client.core.relayer.publish(s, h, l)) : this.client.core.relayer.publish(s, h, l).catch(w => this.client.logger.error(w));
      }
      await this.client.core.history.resolve(c);
    }, this.sendError = async e => {
      const {
          id: t,
          topic: s,
          error: i,
          encodeOpts: r,
          rpcOpts: n,
          appLink: a
        } = e,
        c = ns(t, i);
      let h;
      const p = a && typeof (global == null ? void 0 : global.Linking) < "u";
      try {
        const l = p ? ie : de;
        h = await this.client.core.crypto.encode(s, c, D(I({}, r || {}), {
          encoding: l
        }));
      } catch (l) {
        throw await this.cleanup(), this.client.logger.error(`sendError() -> core.crypto.encode() for topic ${s} failed`), l;
      }
      let d;
      try {
        d = await this.client.core.history.get(s, t);
      } catch (l) {
        throw this.client.logger.error(`sendError() -> history.get(${s}, ${t}) failed`), l;
      }
      if (p) {
        const l = he(a, s, h);
        await global.Linking.openURL(l, this.client.name);
      } else {
        const l = n || v[d.request.method].res;
        this.client.core.relayer.publish(s, h, l);
      }
      await this.client.core.history.resolve(c);
    }, this.cleanup = async () => {
      const e = [],
        t = [];
      this.client.session.getAll().forEach(s => {
        let i = !1;
        J(s.expiry) && (i = !0), this.client.core.crypto.keychain.has(s.topic) || (i = !0), i && e.push(s.topic);
      }), this.client.proposal.getAll().forEach(s => {
        J(s.expiryTimestamp) && t.push(s.id);
      }), await Promise.all([...e.map(s => this.deleteSession({
        topic: s
      })), ...t.map(s => this.deleteProposal(s))]);
    }, this.onRelayEventRequest = async e => {
      this.requestQueue.queue.push(e), await this.processRequestsQueue();
    }, this.processRequestsQueue = async () => {
      if (this.requestQueue.state === x.active) {
        this.client.logger.info("Request queue already active, skipping...");
        return;
      }
      for (this.client.logger.info(`Request queue starting with ${this.requestQueue.queue.length} requests`); this.requestQueue.queue.length > 0;) {
        this.requestQueue.state = x.active;
        const e = this.requestQueue.queue.shift();
        if (e) try {
          await this.processRequest(e);
        } catch (t) {
          this.client.logger.warn(t);
        }
      }
      this.requestQueue.state = x.idle;
    }, this.processRequest = async e => {
      const {
          topic: t,
          payload: s,
          attestation: i,
          transportType: r,
          encryptedId: n
        } = e,
        a = s.method;
      if (!this.shouldIgnorePairingRequest({
        topic: t,
        requestMethod: a
      })) switch (a) {
        case "wc_sessionPropose":
          return await this.onSessionProposeRequest({
            topic: t,
            payload: s,
            attestation: i,
            encryptedId: n
          });
        case "wc_sessionSettle":
          return await this.onSessionSettleRequest(t, s);
        case "wc_sessionUpdate":
          return await this.onSessionUpdateRequest(t, s);
        case "wc_sessionExtend":
          return await this.onSessionExtendRequest(t, s);
        case "wc_sessionPing":
          return await this.onSessionPingRequest(t, s);
        case "wc_sessionDelete":
          return await this.onSessionDeleteRequest(t, s);
        case "wc_sessionRequest":
          return await this.onSessionRequest({
            topic: t,
            payload: s,
            attestation: i,
            encryptedId: n,
            transportType: r
          });
        case "wc_sessionEvent":
          return await this.onSessionEventRequest(t, s);
        case "wc_sessionAuthenticate":
          return await this.onSessionAuthenticateRequest({
            topic: t,
            payload: s,
            attestation: i,
            encryptedId: n,
            transportType: r
          });
        default:
          return this.client.logger.info(`Unsupported request method ${a}`);
      }
    }, this.onRelayEventResponse = async e => {
      const {
          topic: t,
          payload: s,
          transportType: i
        } = e,
        r = (await this.client.core.history.get(t, s.id)).request.method;
      switch (r) {
        case "wc_sessionPropose":
          return this.onSessionProposeResponse(t, s, i);
        case "wc_sessionSettle":
          return this.onSessionSettleResponse(t, s);
        case "wc_sessionUpdate":
          return this.onSessionUpdateResponse(t, s);
        case "wc_sessionExtend":
          return this.onSessionExtendResponse(t, s);
        case "wc_sessionPing":
          return this.onSessionPingResponse(t, s);
        case "wc_sessionRequest":
          return this.onSessionRequestResponse(t, s);
        case "wc_sessionAuthenticate":
          return this.onSessionAuthenticateResponse(t, s);
        default:
          return this.client.logger.info(`Unsupported response method ${r}`);
      }
    }, this.onRelayEventUnknownPayload = e => {
      const {
          topic: t
        } = e,
        {
          message: s
        } = u("MISSING_OR_INVALID", `Decoded payload on topic ${t} is not identifiable as a JSON-RPC request or a response.`);
      throw new Error(s);
    }, this.shouldIgnorePairingRequest = e => {
      const {
          topic: t,
          requestMethod: s
        } = e,
        i = this.expectedPairingMethodMap.get(t);
      return !i || i.includes(s) ? !1 : !!(i.includes("wc_sessionAuthenticate") && this.client.events.listenerCount("session_authenticate") > 0);
    }, this.onSessionProposeRequest = async e => {
      const {
          topic: t,
          payload: s,
          attestation: i,
          encryptedId: r
        } = e,
        {
          params: n,
          id: a
        } = s;
      try {
        const c = this.client.core.eventClient.getEvent({
          topic: t
        });
        this.isValidConnect(I({}, s.params));
        const h = n.expiryTimestamp || A(v.wc_sessionPropose.req.ttl),
          p = I({
            id: a,
            pairingTopic: t,
            expiryTimestamp: h
          }, n);
        await this.setProposal(a, p);
        const d = await this.getVerifyContext({
          attestationId: i,
          hash: X(JSON.stringify(s)),
          encryptedId: r,
          metadata: p.proposer.metadata
        });
        this.client.events.listenerCount("session_proposal") === 0 && (console.warn("No listener for session_proposal event"), c?.setError(Rt.proposal_listener_not_found)), c?.addTrace(St.emit_session_proposal), this.client.events.emit("session_proposal", {
          id: a,
          params: p,
          verifyContext: d
        });
      } catch (c) {
        await this.sendError({
          id: a,
          topic: t,
          error: c,
          rpcOpts: v.wc_sessionPropose.autoReject
        }), this.client.logger.error(c);
      }
    }, this.onSessionProposeResponse = async (e, t, s) => {
      const {
        id: i
      } = t;
      if (K(t)) {
        const {
          result: r
        } = t;
        this.client.logger.trace({
          type: "method",
          method: "onSessionProposeResponse",
          result: r
        });
        const n = this.client.proposal.get(i);
        this.client.logger.trace({
          type: "method",
          method: "onSessionProposeResponse",
          proposal: n
        });
        const a = n.proposer.publicKey;
        this.client.logger.trace({
          type: "method",
          method: "onSessionProposeResponse",
          selfPublicKey: a
        });
        const c = r.responderPublicKey;
        this.client.logger.trace({
          type: "method",
          method: "onSessionProposeResponse",
          peerPublicKey: c
        });
        const h = await this.client.core.crypto.generateSharedKey(a, c);
        this.client.logger.trace({
          type: "method",
          method: "onSessionProposeResponse",
          sessionTopic: h
        });
        const p = await this.client.core.relayer.subscribe(h, {
          transportType: s
        });
        this.client.logger.trace({
          type: "method",
          method: "onSessionProposeResponse",
          subscriptionId: p
        }), await this.client.core.pairing.activate({
          topic: e
        });
      } else if (U(t)) {
        await this.client.proposal.delete(i, P("USER_DISCONNECTED"));
        const r = g("session_connect");
        if (this.events.listenerCount(r) === 0) throw new Error(`emitting ${r} without any listeners, 954`);
        this.events.emit(g("session_connect"), {
          error: t.error
        });
      }
    }, this.onSessionSettleRequest = async (e, t) => {
      const {
        id: s,
        params: i
      } = t;
      try {
        this.isValidSessionSettleRequest(i);
        const {
            relay: r,
            controller: n,
            expiry: a,
            namespaces: c,
            sessionProperties: h,
            sessionConfig: p
          } = t.params,
          d = D(I(I({
            topic: e,
            relay: r,
            expiry: a,
            namespaces: c,
            acknowledged: !0,
            pairingTopic: "",
            requiredNamespaces: {},
            optionalNamespaces: {},
            controller: n.publicKey,
            self: {
              publicKey: "",
              metadata: this.client.metadata
            },
            peer: {
              publicKey: n.publicKey,
              metadata: n.metadata
            }
          }, h && {
            sessionProperties: h
          }), p && {
            sessionConfig: p
          }), {
            transportType: f.relay
          }),
          l = g("session_connect");
        if (this.events.listenerCount(l) === 0) throw new Error(`emitting ${l} without any listeners 997`);
        this.events.emit(g("session_connect"), {
          session: d
        }), await this.sendResult({
          id: t.id,
          topic: e,
          result: !0,
          throwOnFailedPublish: !0
        });
      } catch (r) {
        await this.sendError({
          id: s,
          topic: e,
          error: r
        }), this.client.logger.error(r);
      }
    }, this.onSessionSettleResponse = async (e, t) => {
      const {
        id: s
      } = t;
      K(t) ? (await this.client.session.update(e, {
        acknowledged: !0
      }), this.events.emit(g("session_approve", s), {})) : U(t) && (await this.client.session.delete(e, P("USER_DISCONNECTED")), this.events.emit(g("session_approve", s), {
        error: t.error
      }));
    }, this.onSessionUpdateRequest = async (e, t) => {
      const {
        params: s,
        id: i
      } = t;
      try {
        const r = `${e}_session_update`,
          n = re.get(r);
        if (n && this.isRequestOutOfSync(n, i)) {
          this.client.logger.info(`Discarding out of sync request - ${i}`), this.sendError({
            id: i,
            topic: e,
            error: P("INVALID_UPDATE_REQUEST")
          });
          return;
        }
        this.isValidUpdate(I({
          topic: e
        }, s));
        try {
          re.set(r, i), await this.client.session.update(e, {
            namespaces: s.namespaces
          }), await this.sendResult({
            id: i,
            topic: e,
            result: !0,
            throwOnFailedPublish: !0
          });
        } catch (a) {
          throw re.delete(r), a;
        }
        this.client.events.emit("session_update", {
          id: i,
          topic: e,
          params: s
        });
      } catch (r) {
        await this.sendError({
          id: i,
          topic: e,
          error: r
        }), this.client.logger.error(r);
      }
    }, this.isRequestOutOfSync = (e, t) => parseInt(t.toString().slice(0, -3)) <= parseInt(e.toString().slice(0, -3)), this.onSessionUpdateResponse = (e, t) => {
      const {
          id: s
        } = t,
        i = g("session_update", s);
      if (this.events.listenerCount(i) === 0) throw new Error(`emitting ${i} without any listeners`);
      K(t) ? this.events.emit(g("session_update", s), {}) : U(t) && this.events.emit(g("session_update", s), {
        error: t.error
      });
    }, this.onSessionExtendRequest = async (e, t) => {
      const {
        id: s
      } = t;
      try {
        this.isValidExtend({
          topic: e
        }), await this.setExpiry(e, A(z)), await this.sendResult({
          id: s,
          topic: e,
          result: !0,
          throwOnFailedPublish: !0
        }), this.client.events.emit("session_extend", {
          id: s,
          topic: e
        });
      } catch (i) {
        await this.sendError({
          id: s,
          topic: e,
          error: i
        }), this.client.logger.error(i);
      }
    }, this.onSessionExtendResponse = (e, t) => {
      const {
          id: s
        } = t,
        i = g("session_extend", s);
      if (this.events.listenerCount(i) === 0) throw new Error(`emitting ${i} without any listeners`);
      K(t) ? this.events.emit(g("session_extend", s), {}) : U(t) && this.events.emit(g("session_extend", s), {
        error: t.error
      });
    }, this.onSessionPingRequest = async (e, t) => {
      const {
        id: s
      } = t;
      try {
        this.isValidPing({
          topic: e
        }), await this.sendResult({
          id: s,
          topic: e,
          result: !0,
          throwOnFailedPublish: !0
        }), this.client.events.emit("session_ping", {
          id: s,
          topic: e
        });
      } catch (i) {
        await this.sendError({
          id: s,
          topic: e,
          error: i
        }), this.client.logger.error(i);
      }
    }, this.onSessionPingResponse = (e, t) => {
      const {
          id: s
        } = t,
        i = g("session_ping", s);
      if (this.events.listenerCount(i) === 0) throw new Error(`emitting ${i} without any listeners`);
      setTimeout(() => {
        K(t) ? this.events.emit(g("session_ping", s), {}) : U(t) && this.events.emit(g("session_ping", s), {
          error: t.error
        });
      }, 500);
    }, this.onSessionDeleteRequest = async (e, t) => {
      const {
        id: s
      } = t;
      try {
        this.isValidDisconnect({
          topic: e,
          reason: t.params
        }), Promise.all([new Promise(i => {
          this.client.core.relayer.once(Ue.publish, async () => {
            i(await this.deleteSession({
              topic: e,
              id: s
            }));
          });
        }), this.sendResult({
          id: s,
          topic: e,
          result: !0,
          throwOnFailedPublish: !0
        }), this.cleanupPendingSentRequestsForTopic({
          topic: e,
          error: P("USER_DISCONNECTED")
        })]).catch(i => this.client.logger.error(i));
      } catch (i) {
        this.client.logger.error(i);
      }
    }, this.onSessionRequest = async e => {
      var t, s, i;
      const {
          topic: r,
          payload: n,
          attestation: a,
          encryptedId: c,
          transportType: h
        } = e,
        {
          id: p,
          params: d
        } = n;
      try {
        await this.isValidRequest(I({
          topic: r
        }, d));
        const l = this.client.session.get(r),
          w = await this.getVerifyContext({
            attestationId: a,
            hash: X(JSON.stringify(ge("wc_sessionRequest", d, p))),
            encryptedId: c,
            metadata: l.peer.metadata,
            transportType: h
          }),
          m = {
            id: p,
            topic: r,
            params: d,
            verifyContext: w
          };
        await this.setPendingSessionRequest(m), h === f.link_mode && (t = l.peer.metadata.redirect) != null && t.universal && this.client.core.addLinkModeSupportedApp((s = l.peer.metadata.redirect) == null ? void 0 : s.universal), (i = this.client.signConfig) != null && i.disableRequestQueue ? this.emitSessionRequest(m) : (this.addSessionRequestToSessionRequestQueue(m), this.processSessionRequestQueue());
      } catch (l) {
        await this.sendError({
          id: p,
          topic: r,
          error: l
        }), this.client.logger.error(l);
      }
    }, this.onSessionRequestResponse = (e, t) => {
      const {
          id: s
        } = t,
        i = g("session_request", s);
      if (this.events.listenerCount(i) === 0) throw new Error(`emitting ${i} without any listeners`);
      K(t) ? this.events.emit(g("session_request", s), {
        result: t.result
      }) : U(t) && this.events.emit(g("session_request", s), {
        error: t.error
      });
    }, this.onSessionEventRequest = async (e, t) => {
      const {
        id: s,
        params: i
      } = t;
      try {
        const r = `${e}_session_event_${i.event.name}`,
          n = re.get(r);
        if (n && this.isRequestOutOfSync(n, s)) {
          this.client.logger.info(`Discarding out of sync request - ${s}`);
          return;
        }
        this.isValidEmit(I({
          topic: e
        }, i)), this.client.events.emit("session_event", {
          id: s,
          topic: e,
          params: i
        }), re.set(r, s);
      } catch (r) {
        await this.sendError({
          id: s,
          topic: e,
          error: r
        }), this.client.logger.error(r);
      }
    }, this.onSessionAuthenticateResponse = (e, t) => {
      const {
        id: s
      } = t;
      this.client.logger.trace({
        type: "method",
        method: "onSessionAuthenticateResponse",
        topic: e,
        payload: t
      }), K(t) ? this.events.emit(g("session_request", s), {
        result: t.result
      }) : U(t) && this.events.emit(g("session_request", s), {
        error: t.error
      });
    }, this.onSessionAuthenticateRequest = async e => {
      var t;
      const {
        topic: s,
        payload: i,
        attestation: r,
        encryptedId: n,
        transportType: a
      } = e;
      try {
        const {
            requester: c,
            authPayload: h,
            expiryTimestamp: p
          } = i.params,
          d = await this.getVerifyContext({
            attestationId: r,
            hash: X(JSON.stringify(i)),
            encryptedId: n,
            metadata: c.metadata,
            transportType: a
          }),
          l = {
            requester: c,
            pairingTopic: s,
            id: i.id,
            authPayload: h,
            verifyContext: d,
            expiryTimestamp: p
          };
        await this.setAuthRequest(i.id, {
          request: l,
          pairingTopic: s,
          transportType: a
        }), a === f.link_mode && (t = c.metadata.redirect) != null && t.universal && this.client.core.addLinkModeSupportedApp(c.metadata.redirect.universal), this.client.events.emit("session_authenticate", {
          topic: s,
          params: i.params,
          id: i.id,
          verifyContext: d
        });
      } catch (c) {
        this.client.logger.error(c);
        const h = i.params.requester.publicKey,
          p = await this.client.core.crypto.generateKeyPair(),
          d = this.getAppLinkIfEnabled(i.params.requester.metadata, a),
          l = {
            type: Ae,
            receiverPublicKey: h,
            senderPublicKey: p
          };
        await this.sendError({
          id: i.id,
          topic: s,
          error: c,
          encodeOpts: l,
          rpcOpts: v.wc_sessionAuthenticate.autoReject,
          appLink: d
        });
      }
    }, this.addSessionRequestToSessionRequestQueue = e => {
      this.sessionRequestQueue.queue.push(e);
    }, this.cleanupAfterResponse = e => {
      this.deletePendingSessionRequest(e.response.id, {
        message: "fulfilled",
        code: 0
      }), setTimeout(() => {
        this.sessionRequestQueue.state = x.idle, this.processSessionRequestQueue();
      }, tt(this.requestQueueDelay));
    }, this.cleanupPendingSentRequestsForTopic = ({
      topic: e,
      error: t
    }) => {
      const s = this.client.core.history.pending;
      s.length > 0 && s.filter(i => i.topic === e && i.request.method === "wc_sessionRequest").forEach(i => {
        const r = i.request.id,
          n = g("session_request", r);
        if (this.events.listenerCount(n) === 0) throw new Error(`emitting ${n} without any listeners`);
        this.events.emit(g("session_request", i.request.id), {
          error: t
        });
      });
    }, this.processSessionRequestQueue = () => {
      if (this.sessionRequestQueue.state === x.active) {
        this.client.logger.info("session request queue is already active.");
        return;
      }
      const e = this.sessionRequestQueue.queue[0];
      if (!e) {
        this.client.logger.info("session request queue is empty.");
        return;
      }
      try {
        this.sessionRequestQueue.state = x.active, this.emitSessionRequest(e);
      } catch (t) {
        this.client.logger.error(t);
      }
    }, this.emitSessionRequest = e => {
      this.client.events.emit("session_request", e);
    }, this.onPairingCreated = e => {
      if (e.methods && this.expectedPairingMethodMap.set(e.topic, e.methods), e.active) return;
      const t = this.client.proposal.getAll().find(s => s.pairingTopic === e.topic);
      t && this.onSessionProposeRequest({
        topic: e.topic,
        payload: ge("wc_sessionPropose", {
          requiredNamespaces: t.requiredNamespaces,
          optionalNamespaces: t.optionalNamespaces,
          relays: t.relays,
          proposer: t.proposer,
          sessionProperties: t.sessionProperties
        }, t.id)
      });
    }, this.isValidConnect = async e => {
      if (!L(e)) {
        const {
          message: a
        } = u("MISSING_OR_INVALID", `connect() params: ${JSON.stringify(e)}`);
        throw new Error(a);
      }
      const {
        pairingTopic: t,
        requiredNamespaces: s,
        optionalNamespaces: i,
        sessionProperties: r,
        relays: n
      } = e;
      if (ne(t) || (await this.isValidPairingTopic(t)), !kt(n, !0)) {
        const {
          message: a
        } = u("MISSING_OR_INVALID", `connect() relays: ${n}`);
        throw new Error(a);
      }
      !ne(s) && Xe(s) !== 0 && this.validateNamespaces(s, "requiredNamespaces"), !ne(i) && Xe(i) !== 0 && this.validateNamespaces(i, "optionalNamespaces"), ne(r) || this.validateSessionProps(r, "sessionProperties");
    }, this.validateNamespaces = (e, t) => {
      const s = $t(e, "connect()", t);
      if (s) throw new Error(s.message);
    }, this.isValidApprove = async e => {
      if (!L(e)) throw new Error(u("MISSING_OR_INVALID", `approve() params: ${e}`).message);
      const {
        id: t,
        namespaces: s,
        relayProtocol: i,
        sessionProperties: r
      } = e;
      this.checkRecentlyDeleted(t), await this.isValidProposalId(t);
      const n = this.client.proposal.get(t),
        a = Oe(s, "approve()");
      if (a) throw new Error(a.message);
      const c = Je(n.requiredNamespaces, s, "approve()");
      if (c) throw new Error(c.message);
      if (!k(i, !0)) {
        const {
          message: h
        } = u("MISSING_OR_INVALID", `approve() relayProtocol: ${i}`);
        throw new Error(h);
      }
      ne(r) || this.validateSessionProps(r, "sessionProperties");
    }, this.isValidReject = async e => {
      if (!L(e)) {
        const {
          message: i
        } = u("MISSING_OR_INVALID", `reject() params: ${e}`);
        throw new Error(i);
      }
      const {
        id: t,
        reason: s
      } = e;
      if (this.checkRecentlyDeleted(t), await this.isValidProposalId(t), !Kt(s)) {
        const {
          message: i
        } = u("MISSING_OR_INVALID", `reject() reason: ${JSON.stringify(s)}`);
        throw new Error(i);
      }
    }, this.isValidSessionSettleRequest = e => {
      if (!L(e)) {
        const {
          message: c
        } = u("MISSING_OR_INVALID", `onSessionSettleRequest() params: ${e}`);
        throw new Error(c);
      }
      const {
        relay: t,
        controller: s,
        namespaces: i,
        expiry: r
      } = e;
      if (!Ut(t)) {
        const {
          message: c
        } = u("MISSING_OR_INVALID", "onSessionSettleRequest() relay protocol should be a string");
        throw new Error(c);
      }
      const n = Gt(s, "onSessionSettleRequest()");
      if (n) throw new Error(n.message);
      const a = Oe(i, "onSessionSettleRequest()");
      if (a) throw new Error(a.message);
      if (J(r)) {
        const {
          message: c
        } = u("EXPIRED", "onSessionSettleRequest()");
        throw new Error(c);
      }
    }, this.isValidUpdate = async e => {
      if (!L(e)) {
        const {
          message: a
        } = u("MISSING_OR_INVALID", `update() params: ${e}`);
        throw new Error(a);
      }
      const {
        topic: t,
        namespaces: s
      } = e;
      this.checkRecentlyDeleted(t), await this.isValidSessionTopic(t);
      const i = this.client.session.get(t),
        r = Oe(s, "update()");
      if (r) throw new Error(r.message);
      const n = Je(i.requiredNamespaces, s, "update()");
      if (n) throw new Error(n.message);
    }, this.isValidExtend = async e => {
      if (!L(e)) {
        const {
          message: s
        } = u("MISSING_OR_INVALID", `extend() params: ${e}`);
        throw new Error(s);
      }
      const {
        topic: t
      } = e;
      this.checkRecentlyDeleted(t), await this.isValidSessionTopic(t);
    }, this.isValidRequest = async e => {
      if (!L(e)) {
        const {
          message: a
        } = u("MISSING_OR_INVALID", `request() params: ${e}`);
        throw new Error(a);
      }
      const {
        topic: t,
        request: s,
        chainId: i,
        expiry: r
      } = e;
      this.checkRecentlyDeleted(t), await this.isValidSessionTopic(t);
      const {
        namespaces: n
      } = this.client.session.get(t);
      if (!Be(n, i)) {
        const {
          message: a
        } = u("MISSING_OR_INVALID", `request() chainId: ${i}`);
        throw new Error(a);
      }
      if (!jt(s)) {
        const {
          message: a
        } = u("MISSING_OR_INVALID", `request() ${JSON.stringify(s)}`);
        throw new Error(a);
      }
      if (!Ft(n, i, s.method)) {
        const {
          message: a
        } = u("MISSING_OR_INVALID", `request() method: ${s.method}`);
        throw new Error(a);
      }
      if (r && !Qt(r, me)) {
        const {
          message: a
        } = u("MISSING_OR_INVALID", `request() expiry: ${r}. Expiry must be a number (in seconds) between ${me.min} and ${me.max}`);
        throw new Error(a);
      }
    }, this.isValidRespond = async e => {
      var t;
      if (!L(e)) {
        const {
          message: r
        } = u("MISSING_OR_INVALID", `respond() params: ${e}`);
        throw new Error(r);
      }
      const {
        topic: s,
        response: i
      } = e;
      try {
        await this.isValidSessionTopic(s);
      } catch (r) {
        throw (t = e?.response) != null && t.id && this.cleanupAfterResponse(e), r;
      }
      if (!zt(i)) {
        const {
          message: r
        } = u("MISSING_OR_INVALID", `respond() response: ${JSON.stringify(i)}`);
        throw new Error(r);
      }
    }, this.isValidPing = async e => {
      if (!L(e)) {
        const {
          message: s
        } = u("MISSING_OR_INVALID", `ping() params: ${e}`);
        throw new Error(s);
      }
      const {
        topic: t
      } = e;
      await this.isValidSessionOrPairingTopic(t);
    }, this.isValidEmit = async e => {
      if (!L(e)) {
        const {
          message: n
        } = u("MISSING_OR_INVALID", `emit() params: ${e}`);
        throw new Error(n);
      }
      const {
        topic: t,
        event: s,
        chainId: i
      } = e;
      await this.isValidSessionTopic(t);
      const {
        namespaces: r
      } = this.client.session.get(t);
      if (!Be(r, i)) {
        const {
          message: n
        } = u("MISSING_OR_INVALID", `emit() chainId: ${i}`);
        throw new Error(n);
      }
      if (!Ht(s)) {
        const {
          message: n
        } = u("MISSING_OR_INVALID", `emit() event: ${JSON.stringify(s)}`);
        throw new Error(n);
      }
      if (!Yt(r, i, s.name)) {
        const {
          message: n
        } = u("MISSING_OR_INVALID", `emit() event: ${JSON.stringify(s)}`);
        throw new Error(n);
      }
    }, this.isValidDisconnect = async e => {
      if (!L(e)) {
        const {
          message: s
        } = u("MISSING_OR_INVALID", `disconnect() params: ${e}`);
        throw new Error(s);
      }
      const {
        topic: t
      } = e;
      await this.isValidSessionOrPairingTopic(t);
    }, this.isValidAuthenticate = e => {
      const {
        chains: t,
        uri: s,
        domain: i,
        nonce: r
      } = e;
      if (!Array.isArray(t) || t.length === 0) throw new Error("chains is required and must be a non-empty array");
      if (!k(s, !1)) throw new Error("uri is required parameter");
      if (!k(i, !1)) throw new Error("domain is required parameter");
      if (!k(r, !1)) throw new Error("nonce is required parameter");
      if ([...new Set(t.map(a => Ne(a).namespace))].length > 1) throw new Error("Multi-namespace requests are not supported. Please request single namespace only.");
      const {
        namespace: n
      } = Ne(t[0]);
      if (n !== "eip155") throw new Error("Only eip155 namespace is supported for authenticated sessions. Please use .connect() for non-eip155 chains.");
    }, this.getVerifyContext = async e => {
      const {
          attestationId: t,
          hash: s,
          encryptedId: i,
          metadata: r,
          transportType: n
        } = e,
        a = {
          verified: {
            verifyUrl: r.verifyUrl || It,
            validation: "UNKNOWN",
            origin: r.url || ""
          }
        };
      try {
        if (n === f.link_mode) {
          const h = this.getAppLinkIfEnabled(r, n);
          return a.verified.validation = h && new URL(h).origin === new URL(r.url).origin ? "VALID" : "INVALID", a;
        }
        const c = await this.client.core.verify.resolve({
          attestationId: t,
          hash: s,
          encryptedId: i,
          verifyUrl: r.verifyUrl
        });
        c && (a.verified.origin = c.origin, a.verified.isScam = c.isScam, a.verified.validation = c.origin === new URL(r.url).origin ? "VALID" : "INVALID");
      } catch (c) {
        this.client.logger.warn(c);
      }
      return this.client.logger.debug(`Verify context: ${JSON.stringify(a)}`), a;
    }, this.validateSessionProps = (e, t) => {
      Object.values(e).forEach(s => {
        if (!k(s, !1)) {
          const {
            message: i
          } = u("MISSING_OR_INVALID", `${t} must be in Record<string, string> format. Received: ${JSON.stringify(s)}`);
          throw new Error(i);
        }
      });
    }, this.getPendingAuthRequest = e => {
      const t = this.client.auth.requests.get(e);
      return typeof t == "object" ? t : void 0;
    }, this.addToRecentlyDeleted = (e, t) => {
      if (this.recentlyDeletedMap.set(e, t), this.recentlyDeletedMap.size >= this.recentlyDeletedLimit) {
        let s = 0;
        const i = this.recentlyDeletedLimit / 2;
        for (const r of this.recentlyDeletedMap.keys()) {
          if (s++ >= i) break;
          this.recentlyDeletedMap.delete(r);
        }
      }
    }, this.checkRecentlyDeleted = e => {
      const t = this.recentlyDeletedMap.get(e);
      if (t) {
        const {
          message: s
        } = u("MISSING_OR_INVALID", `Record was recently deleted - ${t}: ${e}`);
        throw new Error(s);
      }
    }, this.isLinkModeEnabled = (e, t) => {
      var s, i, r, n, a, c, h, p, d;
      return !e || t !== f.link_mode ? !1 : ((i = (s = this.client.metadata) == null ? void 0 : s.redirect) == null ? void 0 : i.linkMode) === !0 && ((n = (r = this.client.metadata) == null ? void 0 : r.redirect) == null ? void 0 : n.universal) !== void 0 && ((c = (a = this.client.metadata) == null ? void 0 : a.redirect) == null ? void 0 : c.universal) !== "" && ((h = e?.redirect) == null ? void 0 : h.universal) !== void 0 && ((p = e?.redirect) == null ? void 0 : p.universal) !== "" && ((d = e?.redirect) == null ? void 0 : d.linkMode) === !0 && this.client.core.linkModeSupportedApps.includes(e.redirect.universal) && typeof (global == null ? void 0 : global.Linking) < "u";
    }, this.getAppLinkIfEnabled = (e, t) => {
      var s;
      return this.isLinkModeEnabled(e, t) ? (s = e?.redirect) == null ? void 0 : s.universal : void 0;
    }, this.handleLinkModeMessage = ({
      url: e
    }) => {
      if (!e || !e.includes("wc_ev") || !e.includes("topic")) return;
      const t = We(e, "topic") || "",
        s = decodeURIComponent(We(e, "wc_ev") || ""),
        i = this.client.session.keys.includes(t);
      i && this.client.session.update(t, {
        transportType: f.link_mode
      }), this.client.core.dispatchEnvelope({
        topic: t,
        message: s,
        sessionExists: i
      });
    }, this.registerLinkModeListeners = async () => {
      var e;
      if (Xt() || Jt() && (e = this.client.metadata.redirect) != null && e.linkMode) {
        const t = global == null ? void 0 : global.Linking;
        if (typeof t < "u") {
          t.addEventListener("url", this.handleLinkModeMessage, this.client.name);
          const s = await t.getInitialURL();
          s && setTimeout(() => {
            this.handleLinkModeMessage({
              url: s
            });
          }, 50);
        }
      }
    };
  }
  isInitialized() {
    if (!this.initialized) {
      const {
        message: o
      } = u("NOT_INITIALIZED", this.name);
      throw new Error(o);
    }
  }
  async confirmOnlineStateOrThrow() {
    await this.client.core.relayer.confirmOnlineStateOrThrow();
  }
  registerRelayerEvents() {
    this.client.core.relayer.on(Ue.message, o => {
      !this.initialized || this.relayMessageCache.length > 0 ? this.relayMessageCache.push(o) : this.onRelayMessage(o);
    });
  }
  async onRelayMessage(o) {
    const {
        topic: e,
        message: t,
        attestation: s,
        transportType: i
      } = o,
      {
        publicKey: r
      } = this.client.auth.authKeys.keys.includes(ae) ? this.client.auth.authKeys.get(ae) : {
        responseTopic: void 0,
        publicKey: void 0
      },
      n = await this.client.core.crypto.decode(e, t, {
        receiverPublicKey: r,
        encoding: i === f.link_mode ? ie : de
      });
    try {
      os(n) ? (this.client.core.history.set(e, n), this.onRelayEventRequest({
        topic: e,
        payload: n,
        attestation: s,
        transportType: i,
        encryptedId: X(t)
      })) : as(n) ? (await this.client.core.history.resolve(n), await this.onRelayEventResponse({
        topic: e,
        payload: n,
        transportType: i
      }), this.client.core.history.delete(e, n.id)) : this.onRelayEventUnknownPayload({
        topic: e,
        payload: n,
        transportType: i
      });
    } catch (a) {
      this.client.logger.error(a);
    }
  }
  registerExpirerEvents() {
    this.client.core.expirer.on(ft.expired, async o => {
      const {
        topic: e,
        id: t
      } = Bt(o.target);
      if (t && this.client.pendingRequest.keys.includes(t)) return await this.deletePendingSessionRequest(t, u("EXPIRED"), !0);
      if (t && this.client.auth.requests.keys.includes(t)) return await this.deletePendingAuthRequest(t, u("EXPIRED"), !0);
      e ? this.client.session.keys.includes(e) && (await this.deleteSession({
        topic: e,
        expirerHasDeleted: !0
      }), this.client.events.emit("session_expire", {
        topic: e
      })) : t && (await this.deleteProposal(t, !0), this.client.events.emit("proposal_expire", {
        id: t
      }));
    });
  }
  registerPairingEvents() {
    this.client.core.pairing.events.on(Ge.create, o => this.onPairingCreated(o)), this.client.core.pairing.events.on(Ge.delete, o => {
      this.addToRecentlyDeleted(o.topic, "pairing");
    });
  }
  isValidPairingTopic(o) {
    if (!k(o, !1)) {
      const {
        message: e
      } = u("MISSING_OR_INVALID", `pairing topic should be a string: ${o}`);
      throw new Error(e);
    }
    if (!this.client.core.pairing.pairings.keys.includes(o)) {
      const {
        message: e
      } = u("NO_MATCHING_KEY", `pairing topic doesn't exist: ${o}`);
      throw new Error(e);
    }
    if (J(this.client.core.pairing.pairings.get(o).expiry)) {
      const {
        message: e
      } = u("EXPIRED", `pairing topic: ${o}`);
      throw new Error(e);
    }
  }
  async isValidSessionTopic(o) {
    if (!k(o, !1)) {
      const {
        message: e
      } = u("MISSING_OR_INVALID", `session topic should be a string: ${o}`);
      throw new Error(e);
    }
    if (this.checkRecentlyDeleted(o), !this.client.session.keys.includes(o)) {
      const {
        message: e
      } = u("NO_MATCHING_KEY", `session topic doesn't exist: ${o}`);
      throw new Error(e);
    }
    if (J(this.client.session.get(o).expiry)) {
      await this.deleteSession({
        topic: o
      });
      const {
        message: e
      } = u("EXPIRED", `session topic: ${o}`);
      throw new Error(e);
    }
    if (!this.client.core.crypto.keychain.has(o)) {
      const {
        message: e
      } = u("MISSING_OR_INVALID", `session topic does not exist in keychain: ${o}`);
      throw await this.deleteSession({
        topic: o
      }), new Error(e);
    }
  }
  async isValidSessionOrPairingTopic(o) {
    if (this.checkRecentlyDeleted(o), this.client.session.keys.includes(o)) await this.isValidSessionTopic(o);else if (this.client.core.pairing.pairings.keys.includes(o)) this.isValidPairingTopic(o);else if (k(o, !1)) {
      const {
        message: e
      } = u("NO_MATCHING_KEY", `session or pairing topic doesn't exist: ${o}`);
      throw new Error(e);
    } else {
      const {
        message: e
      } = u("MISSING_OR_INVALID", `session or pairing topic should be a string: ${o}`);
      throw new Error(e);
    }
  }
  async isValidProposalId(o) {
    if (!Wt(o)) {
      const {
        message: e
      } = u("MISSING_OR_INVALID", `proposal id should be a number: ${o}`);
      throw new Error(e);
    }
    if (!this.client.proposal.keys.includes(o)) {
      const {
        message: e
      } = u("NO_MATCHING_KEY", `proposal id doesn't exist: ${o}`);
      throw new Error(e);
    }
    if (J(this.client.proposal.get(o).expiryTimestamp)) {
      await this.deleteProposal(o);
      const {
        message: e
      } = u("EXPIRED", `proposal id: ${o}`);
      throw new Error(e);
    }
  }
}
class Ss extends H {
  constructor(o, e) {
    super(o, e, st, ye), this.core = o, this.logger = e;
  }
}
class yt extends H {
  constructor(o, e) {
    super(o, e, rt, ye), this.core = o, this.logger = e;
  }
}
class Is extends H {
  constructor(o, e) {
    super(o, e, ot, ye, t => t.id), this.core = o, this.logger = e;
  }
}
class fs extends H {
  constructor(o, e) {
    super(o, e, pt, oe, () => ae), this.core = o, this.logger = e;
  }
}
class vs extends H {
  constructor(o, e) {
    super(o, e, ht, oe), this.core = o, this.logger = e;
  }
}
class qs extends H {
  constructor(o, e) {
    super(o, e, dt, oe, t => t.id), this.core = o, this.logger = e;
  }
}
class Ts {
  constructor(o, e) {
    this.core = o, this.logger = e, this.authKeys = new fs(this.core, this.logger), this.pairingTopics = new vs(this.core, this.logger), this.requests = new qs(this.core, this.logger);
  }
  async init() {
    await this.authKeys.init(), await this.pairingTopics.init(), await this.requests.init();
  }
}
class _e extends At {
  constructor(o) {
    super(o), this.protocol = be, this.version = Ce, this.name = we.name, this.events = new ts(), this.on = (t, s) => this.events.on(t, s), this.once = (t, s) => this.events.once(t, s), this.off = (t, s) => this.events.off(t, s), this.removeListener = (t, s) => this.events.removeListener(t, s), this.removeAllListeners = t => this.events.removeAllListeners(t), this.connect = async t => {
      try {
        return await this.engine.connect(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.pair = async t => {
      try {
        return await this.engine.pair(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.approve = async t => {
      try {
        return await this.engine.approve(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.reject = async t => {
      try {
        return await this.engine.reject(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.update = async t => {
      try {
        return await this.engine.update(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.extend = async t => {
      try {
        return await this.engine.extend(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.request = async t => {
      try {
        return await this.engine.request(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.respond = async t => {
      try {
        return await this.engine.respond(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.ping = async t => {
      try {
        return await this.engine.ping(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.emit = async t => {
      try {
        return await this.engine.emit(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.disconnect = async t => {
      try {
        return await this.engine.disconnect(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.find = t => {
      try {
        return this.engine.find(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.getPendingSessionRequests = () => {
      try {
        return this.engine.getPendingSessionRequests();
      } catch (t) {
        throw this.logger.error(t.message), t;
      }
    }, this.authenticate = async (t, s) => {
      try {
        return await this.engine.authenticate(t, s);
      } catch (i) {
        throw this.logger.error(i.message), i;
      }
    }, this.formatAuthMessage = t => {
      try {
        return this.engine.formatAuthMessage(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.approveSessionAuthenticate = async t => {
      try {
        return await this.engine.approveSessionAuthenticate(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.rejectSessionAuthenticate = async t => {
      try {
        return await this.engine.rejectSessionAuthenticate(t);
      } catch (s) {
        throw this.logger.error(s.message), s;
      }
    }, this.name = o?.name || we.name, this.metadata = o?.metadata || Zt(), this.signConfig = o?.signConfig;
    const e = typeof o?.logger < "u" && typeof o?.logger != "string" ? o.logger : qt(Tt({
      level: o?.logger || we.logger
    }));
    this.core = o?.core || new vt(o), this.logger = Nt(e, this.name), this.session = new yt(this.core, this.logger), this.proposal = new Ss(this.core, this.logger), this.pendingRequest = new Is(this.core, this.logger), this.engine = new Rs(this), this.auth = new Ts(this.core, this.logger);
  }
  static async init(o) {
    const e = new _e(o);
    return await e.initialize(), e;
  }
  get context() {
    return Pt(this.logger);
  }
  get pairing() {
    return this.core.pairing.pairings;
  }
  async initialize() {
    this.logger.trace("Initialized");
    try {
      await this.core.start(), await this.session.init(), await this.proposal.init(), await this.pendingRequest.init(), await this.auth.init(), await this.engine.init(), this.logger.info("SignClient Initialization Success"), this.engine.processRelayMessageCache();
    } catch (o) {
      throw this.logger.info("SignClient Initialization Failure"), this.logger.error(o.message), o;
    }
  }
}
const Ns = yt,
  Ps = _e;
export { lt as AUTH_CONTEXT, pt as AUTH_KEYS_CONTEXT, ht as AUTH_PAIRING_TOPIC_CONTEXT, ct as AUTH_PROTOCOL, ae as AUTH_PUBLIC_KEY_NAME, dt as AUTH_REQUEST_CONTEXT, oe as AUTH_STORAGE_PREFIX, gs as AUTH_VERSION, nt as ENGINE_CONTEXT, x as ENGINE_QUEUE_STATES, v as ENGINE_RPC_OPTS, hs as HISTORY_CONTEXT, ps as HISTORY_EVENTS, ds as HISTORY_STORAGE_VERSION, at as METHODS_TO_VERIFY, st as PROPOSAL_CONTEXT, us as PROPOSAL_EXPIRY, it as PROPOSAL_EXPIRY_MESSAGE, ot as REQUEST_CONTEXT, rt as SESSION_CONTEXT, z as SESSION_EXPIRY, me as SESSION_REQUEST_EXPIRY_BOUNDARIES, Le as SIGN_CLIENT_CONTEXT, we as SIGN_CLIENT_DEFAULT, cs as SIGN_CLIENT_EVENTS, be as SIGN_CLIENT_PROTOCOL, ls as SIGN_CLIENT_STORAGE_OPTIONS, ye as SIGN_CLIENT_STORAGE_PREFIX, Ce as SIGN_CLIENT_VERSION, Ns as SessionStore, Ps as SignClient, xe as WALLETCONNECT_DEEPLINK_CHOICE, _e as default };
